const state = {
    globalError: 'NoError',
};

const getters = {
    globalError: state => state.globalError,
};

const mutations = {
    SET_GLOBAL_ERROR(state, param) {
        state.globalError = param;
    },
};

export default {
    state,
    getters,
    mutations,
};