const state = {
    notificationTime: JSON.parse(window.localStorage.getItem('notificationTime') || '"12:00"'),
    activeNotification: JSON.parse(window.localStorage.getItem('activeNotification') || 'false')
};

const getters = {
    notificationTime: state => state.notificationTime,
    activeNotification: state => state.activeNotification,
};

const mutations = {
    SET_NOTIFICATION_TIME(state, param) {
        state.notificationTime = param;
        window.localStorage.setItem('notificationTime', JSON.stringify(param));
    },
    SET_ACTIVE_NOTIFICATION(state, param) {
        state.activeNotification = param;
        window.localStorage.setItem('activeNotification', JSON.stringify(param));
    },
};

export default {
    state,
    getters,
    mutations,
};
