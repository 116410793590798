











































































































































import {Component, Vue} from 'vue-property-decorator';
import Questionnaire from "@/components/Questionnaire.vue";
import ContactDialog from "@/components/contacts/ContactDialog.vue";
import Symptoms from "@/components/Symptoms.vue";
import UserEditor from "@/components/user/UserEditor.vue";
import {QuestionStatus} from "@/assets/Entities/User";
import AbsentDialog from "@/components/general/dialogs/AbsentDialog.vue";
import GuestDialog from "@/components/general/dialogs/GuestDialog.vue";
import Webservice from "@/assets/service/Webservice";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import InputComp from "@/components/general/InputComp.vue";
import DatePicker from "@/components/contacts/DatePicker.vue";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {
    DatePicker,
    InputComp,
    GuestDialog,
    AbsentDialog,
    UserEditor,
    Symptoms,
    ContactDialog,
    Questionnaire
  },
})
export default class Home extends Vue {
  questionnaireDialogOpen: boolean = false;
  symptomsDialogOpen: boolean = false;
  contactDialogOpen: boolean = false;
  uploadDialogOpen: boolean = false;
  uploadSuccess: boolean = false;

  image: any = null;
  uploadUntil: string = '';
  isGenes: boolean = false;
  loading: boolean = false;

  showSuccessDialog: boolean = false;
  status: QuestionStatus = QuestionStatus.GoodToGo;

  get successMessage(): string {
    switch (this.status) {
      case QuestionStatus.ShouldCheck:
        return 'ShouldCheck';
      case QuestionStatus.Quarantine:
        return 'Quarantine';
      case QuestionStatus.GoodToGo:
      default:
        return 'GoodToGo';
    }
  }

  openSuccessDialog(status: QuestionStatus) {
    this.status = status;
    this.showSuccessDialog = true;
  }

  get admin() {
    return this.$store.getters.currentUser.type === UserType.Admin
  }

  uploadImage() {
    let params: SchoolURLParams = {
      type: TargetType.CERT,
      client: this.$route.params.client,
      userId: this.$store.getters.currentUser?.id
    };
    //@ts-nocheck
    let formData = new FormData();
    formData.append('image', this.image);
    formData.append('content-type', (this.image as File).type);
    formData.append('date', this.uploadUntil)
    formData.append('type', this.isGenes ? 'vaccination' : 'recovered');
    Webservice.performRequest(RequestType.POST, params,
        formData, val => {
          this.uploadSuccess = true;
        }, error => {/* TODO: handle me */
        }, () => {
          this.loading = false;
          this.uploadUntil = '';
          this.image = undefined;
        });
  }
}
