
















































import {Component, Prop, Vue} from "vue-property-decorator";
import {InputField} from "@/components/general/InputComp.vue";
import AddElementDialog from "@/components/generic/AddElementDialog.vue";
import {UserType} from "@/assets/constants/Enums";
import GenericEntity from "@/assets/Entities/GenericEntity";

@Component({
  components: {AddElementDialog}
})
export default class InputListComponent<T extends GenericEntity> extends Vue {
  @Prop() fieldValues!: InputField<T>;
  @Prop() reloadList!: () => void;
  loading: boolean = false;

  get idList(): string[] {
    let idList: string[] = [];
    if (this.fieldValues.items) {
      for (let i = 0; i < this.fieldValues.items.length; i++) {
        idList.push(this.fieldValues.items[i]._id);
      }
    }
    return idList;
  }

  get canEditClass(): boolean {
    return this.$store.getters.currentUser.role === UserType.Admin || this.$store.getters.currentUser.role === UserType.Site_admin;
  }

  removeElementFromList(element: T) {
    this.loading = true;
    if (this.fieldValues && this.fieldValues.deleteCallback) {
      this.fieldValues.deleteCallback(element,
          () => this.reloadList(),
          () => {
            this.loading = false
          });
    }
  }
}
