
















































import {Component, Vue} from "vue-property-decorator";
import InputComp from "@/components/general/InputComp.vue";
import DatePicker from "@/components/contacts/DatePicker.vue";
import Webservice from "@/assets/service/Webservice";
import {RequestType, TargetType} from "@/assets/constants/Enums";

@Component({
  components: {DatePicker, InputComp}
})
export default class AbsentDialog extends Vue {
  open: boolean = false;
  reason: string = '';
  duration: string[] = [];

  loading: boolean = false;

  close(): void {
    this.duration = [];
    this.reason = '';
    this.open = false;
  }

  save(): void {
    if (this.duration.length === 2) {
      this.loading = true
      Webservice.performRequest(RequestType.POST,
          {
            userId: this.$store.getters.currentUser.id,
            type: TargetType.ABSENT,
            client: this.$route.params.client,
          },
          {
            type: this.reason,
            date_from: this.duration[0],
            date_to: this.duration[1]
          },
          val => {
            this.loading = false;
            this.close()
          });
    }
  }
}
