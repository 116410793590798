


















































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {QrcodeStream} from 'vue-qrcode-reader';
import InputComp from "@/components/general/InputComp.vue";
import {RequestType, TargetType} from "@/assets/constants/Enums";
import Webservice from "@/assets/service/Webservice";
import {GenericWrapper} from "@/assets/Entities/GenericEntity";

enum State {
  INTRO = 'INTRO',
  SCAN = 'SCAN',
  CHOOSE = 'CHOOSE',
  SIGNUP = 'SIGNUP',
  IMAGE = 'IMAGE'
}

class Guest {
  id?: string;
  name?: string;
  company?: string;
  type?: string;
}

@Component({
  components: {
    InputComp,
    QrcodeStream
  }
})
export default class GuestDialog extends Vue {
  private state: State = State.INTRO;
  private guestList: Guest[] = [];
  // public State = State;
  private openDialog: boolean = false;

  selected: number = -1;
  company: string = '';
  name: string = '';
  type: string = '';

  page: number = 1;
  total: number = 0;

  image: any = null;
  companyError = '';
  uploadDialogOpen = false;
  uploadSuccess = false;

  loading = false;

  filter: string = '';

  decodeString: string = '';

  error: boolean = false
  errorText: string = '';

  @Watch('state')
  watchState() {
    if (this.state === State.CHOOSE) {
      this.loading = true;
      this.selected = -1;
      this.company = '';
      this.name = '';
      this.type = '';
      this.page = 1;
      this.loadGuests();
    } else if (this.state === State.SIGNUP) {
      this.selected = -1;
      this.company = '';
      this.name = '';
      this.type = '';
    } else if (this.state === State.SCAN) {
      this.company = '';
      this.decodeString = '';
    }
  }

  @Watch('filter')
  @Watch('page')
  private loadGuests() {
    Webservice.performRequest<GenericWrapper<Guest>>(RequestType.GET, /*TODO Get Guests*/
        {
          type: TargetType.GUEST_LIST,
          client: this.$route.params.client
        },
        {page: this.page, search: this.filter, itemsPerPage: 5}, (val) => {
          if (val && typeof val !== 'string') {
            this.guestList = val.items;
            this.total = val.count;
          }
        }, error => {
        }, () => {
          this.loading = false;
        })
  }

  onDecode(decodeString: string): void {
    this.decodeString = decodeString;
    if (this.company !== '') {
      this.postQr();
    } else {
      this.companyError = 'Guest.Error.Empty'
    }
  }

  postQr() {
    Webservice.performRequest(RequestType.POST, {
      type: TargetType.GUEST_QR,
      client: this.$route.params.client
    }, {token: this.decodeString, company: this.company}, () => {
      this.uploadSuccess = true;
    }, error => {
      // this.error = true;
      // this.errorText = error.response.data.error;
      this.decodeString = '';
    });
  }

  paintBoundingBox(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
      const {boundingBox: {x, y, width, height}} = detectedCode

      ctx.lineWidth = 2
      ctx.strokeStyle = '#007bff'
      ctx.strokeRect(x, y, width, height)
    }
  }

  get guests(): Guest[] {
    return this.guestList;
  }

  get disableNext(): boolean {
    return this.name === ''
        && this.company === '';
  }

  back(): void {
    switch (this.state) {
      case State.CHOOSE:
      case State.IMAGE:
      case State.SIGNUP:
      case State.SCAN:
        this.state = State.INTRO;
    }
  }

  uploadImage(): void {
    this.loading = true;
    let formData = new FormData();

    if (this.selected >= 0) {
      const name = this.guestList[this.selected]?.name;
      if (name) {
        formData.append('name', name);
      }
      const company = this.guestList[this.selected]?.company;
      if (company) {
        formData.append('company', company);
      }
      const type = this.guestList[this.selected]?.type;
      if (type) {
        formData.append('type', type);
      }
    } else if (this.name && this.type && this.company) {
      formData.append('name', this.name);
      formData.append('company', this.company);
      formData.append('type', this.type);
    } else {
      this.loading = false;
      return;
    }

    formData.append('image', this.image);

    formData.append('content-type', (this.image as File).type);

    Webservice.performRequest<Guest[]>(RequestType.POST, {
      type: TargetType.GUEST_IMAGE,
      client: this.$route.params.client
    }, formData, () => {
      this.uploadSuccess = true;
    }, error => {
    }, () => this.loading = false);
  }

  close() {
    this.state = State.INTRO;
    this.company = '';
    this.name = '';
    this.type = '';
    this.openDialog = false;
  }

  next(target: string) {
    switch (target) {
      case 'SCAN':
        this.state = State.SCAN;
        break;
      case 'CHOOSE':
        this.state = State.CHOOSE;
        break;
      case 'SIGNUP':
        this.state = State.SIGNUP;
        break;
      case 'IMAGE':
        this.state = State.IMAGE;
        break;
    }
  }
}
