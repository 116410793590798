import axios from 'axios';
import store from '@/store';

import data from '../config/config.json';
import {getId, isLoggedIn, unsetId} from "@/assets/util/AuthUtil";

const axiosInstance = axios.create({
    baseURL: 'https://k166qo7kf9.execute-api.eu-central-1.amazonaws.com/' + (data?.Repository?.stage || 'dev')
});

if (isLoggedIn()) {
    axiosInstance.defaults.headers.common['Authorization'] = `${getId()}`;
}

axiosInstance.interceptors.response.use(value => {
    return value
}, error => {
    // if (error?.response?.data?.error === undefined) {
        switch (error.response.status) {
            case 400:
                if (error.response.data.error) {
                    store.commit('SET_GLOBAL_ERROR', error.response.data.error)
                } else {
                    store.commit('SET_GLOBAL_ERROR', 'BadRequest');
                }
                break;
            case 403:
                if (error.response.data.message === 'Forbidden') {
                    unsetId();
                }
                break;
            case 404:
                if (error.response.data.error) {
                    store.commit('SET_GLOBAL_ERROR', error.response.data.error)
                } else {
                    store.commit('SET_GLOBAL_ERROR', 'NotFound');
                }
                break;
            case 500:
                store.commit('SET_GLOBAL_ERROR', 'ServerError');
                break;
            default:
        }
    // }
    return  Promise.reject(error);
})

export default axiosInstance;

export const {CancelToken} = axios;
