













































import {Component, Prop, Vue} from "vue-property-decorator";
import DatePicker from "@/components/contacts/DatePicker.vue";
import Contact from "@/assets/Entities/Contact";

@Component({
  components: {DatePicker}
})
export default class ContactDialog extends Vue {
  @Prop() onSend!: () => void;

  content: Contact = new Contact();

  send(): void {
    // TODO: Endpoint for contacts
    this.content = new Contact();
    this.onSend();
  }
}
