







































import {Component, Vue} from 'vue-property-decorator';
import SidemenuEntry from '@/components/general/layout/sidemenu/SidemenuEntry.vue';
import {checkRole, mainSites} from '@/router';
import LogoutDialog from "@/components/general/dialogs/LogoutDialog.vue";
import {RouteConfig} from "vue-router";

@Component({
  components: {
    LogoutDialog,
    SidemenuEntry,
  },
})
export default class Sidemenu extends Vue {
  get elements(): RouteConfig[] {
    return mainSites.filter(value => checkRole(value.meta?.neededUser));
  };

  get showMenu(): boolean {
    return this.$store.getters.sideMenuOpen && this.$route.name !== 'Login';
  }

  set showMenu(open: boolean) {
    this.$store.commit('SET_SIDE_MENU_OPEN', open);
  }

  get schoolName(): string {
    return this.$store.getters.currentUser?.client?.name;
  }
}
