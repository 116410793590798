






































import {Component, Prop, VModel, Vue} from 'vue-property-decorator'

@Component({
  components: {}
})
export default class DatePicker extends Vue {
  @VModel() date!: string | string[];
  @Prop({default: false}) onlyFuture!: Boolean;
  @Prop({default: false}) noBounds!: Boolean;
  @Prop({default: false}) range!: Boolean;
  @Prop({default: ''}) label!: String;
  @Prop({default: ''}) classes!: String;
  open: boolean = false;

  get dateString(): string {
    if (this.date.length > 0 && typeof this.date === 'string') {
      return this.$d(new Date(this.date), 'long').toString();
    } else if (Array.isArray(this.date)) {
      let dateString = '';
      this.date.sort((a, b) => (new Date(a)).getMilliseconds() - (new Date(b)).getMilliseconds());
      this.date.forEach((value, index) => {
        dateString += this.$d(new Date(value), 'long').toString() + ((index + 1) < this.date.length ? ' - ' : '');
      })
      return dateString;
    }
    return ''
  }
}
