import Vue from 'vue';
import Vuex from 'vuex';
import settings from "@/store/modules/settings";
import userRelated from "@/store/modules/userRelated";
import error from '@/store/modules/error';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sideMenuOpen: false
    },
    getters: {
        sideMenuOpen: state => state.sideMenuOpen
    },
    mutations: {
        SET_SIDE_MENU_OPEN(state, param) {
            state.sideMenuOpen = param;
        }
    },
    actions: {},
    modules: {settings,
        userRelated,
    error}
})
