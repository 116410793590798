






















































import {Component, Vue} from 'vue-property-decorator';
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {}
})
export default class Login extends Vue {
  name: string = '';
  password: string = '';
  loading: boolean = false;

  error: string = '';

  passwordVisible: boolean = false;

  login(): void {
    this.loading = true;
    this.error = '';
    Webservice.login(this.name, this.password, (reason) => {
      this.error = this.$t('Error.' + reason).toString();
    }, () => this.loading = false, this.$route.params.client);
  }
}
