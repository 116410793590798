














import {Component, Vue} from 'vue-property-decorator';
import Footer from '@/components/general/layout/Footer.vue';
import TopBar from '@/components/general/layout/Topbar.vue';
import Sidemenu from "@/components/general/layout/sidemenu/Sidemenu.vue";
import ErrorDialog from "@/components/general/dialogs/ErrorDialog.vue";

@Component({
  components: {
    ErrorDialog,
    Sidemenu,
    TopBar,
    Footer,
  },
})
export default class App extends Vue {

}
