



































import {Vue, Component} from "vue-property-decorator";
import InputComp from "@/components/general/InputComp.vue";

@Component({
  components: {InputComp}
})
export default class Report extends Vue {
  options: string[] = [
    'support',
    'error',
    'feature',
    'other'
  ]

  description: string = '';
}
