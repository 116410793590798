var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"50%","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-8",attrs:{"color":"white","width":_vm.$vuetify.breakpoint.mdAndUp ? '50%' : '90%',"x-large":""}},on),[_vm._v(_vm._s(_vm.$t('Home.Absent'))+" "),_c('v-icon',{staticClass:"ml-4"},[_vm._v("mdi-calendar-alert")])],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Home.Absent'))+" ")]),_c('v-card-text',[_c('input-comp',{attrs:{"field-values":{
                                  title: 'Absent.Reason.Title',
                                  type: 'select',
                                  itemText:(function (val) { return _vm.$t('Absent.Reason.' + val.val); }),
                                  itemValue: 'val',
                                  items: [{val:'illness'}, {val:'vacation'}, {val:'homeoffice'}]}},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('date-picker',{attrs:{"classes":"mx-5 mt-4","label":_vm.$t('Absent.Duration').toString(),"no-bounds":true,"range":true},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-4",attrs:{"block":_vm.$vuetify.breakpoint.xs,"large":_vm.$vuetify.breakpoint.smAndUp,"color":"accent"},on:{"click":_vm.save}},[_c('v-icon',{staticClass:"normal-icon",attrs:{"small":_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" mdi-content-save-outline ")]),_vm._v(" "+_vm._s(_vm.$t("Dialog.Save"))+" ")],1),_c('v-btn',{staticClass:"grey white--text",class:{'mt-2':_vm.$vuetify.breakpoint.xs},attrs:{"block":_vm.$vuetify.breakpoint.xs,"large":_vm.$vuetify.breakpoint.smAndUp},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Dialog.Cancel"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }