const state = {
    currentUser: JSON.parse(window.localStorage.getItem('currentUser') || '{}'),
    availableSchools: JSON.parse(window.localStorage.getItem('availableSchools') || '[]'),
    availableClasses: JSON.parse(window.localStorage.getItem('availableClasses') || '[]'),
};

const getters = {
    currentUser: state => state.currentUser,
    availableSchools: state => state.availableSchools,
    availableClasses: state => state.availableClasses,
    // availableQuestionsList: state => state.availableQuestionsList
};

const mutations = {
    SET_CURRENT_USER(state, param) {
        state.currentUser = param;
        window.localStorage.setItem('currentUser', JSON.stringify(param));
    },
    SET_AVAILABLE_SCHOOLS(state, param) {
        state.availableSchools = param;
        window.localStorage.setItem('availableSchools', JSON.stringify(param));
    },
    SET_AVAILABLE_CLASSES(state, param) {
        state.availableClasses = param;
        window.localStorage.setItem('availableClasses', JSON.stringify(param));
    },
    // SET_AVAILABLE_QUESTIONS_LIST(state, param) {
    //     state.availableQuestionsList = param;
    // },
};

export default {
    state,
    getters,
    mutations,
};