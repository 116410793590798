import {deleteCookie, getCookie, setCookie} from "@/assets/util/CookieUtil";

export const isLoggedIn: () => boolean = () => {
    return getCookie('id').length > 0;
}

export const setId: (id: string) => void = (id: string) => {
    setCookie('id', id, 24 * 60 * 60)
}

export const unsetId: () => void = () => {
    deleteCookie('id');
}

export const getId: () => string = () => {
    return getCookie('id');
}
