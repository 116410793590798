


















































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import {SchoolURLParams} from "@/assets/service/Endpoints";
import TimePickerDialog from "@/components/settings/TimePickerDialog.vue";

export class InputField<T> {
  title!: string;
  value?: string;
  type!: string;
  comp?: string;
  rule?: string;
  disabled?: boolean;
  ruleError?: string;
  items?: T[];
  itemText?: string | ((val: string) => string);
  itemValue?: string;
  deleteCallback?: (element: T,callbackSuccess: () => void, callbackFinally: () => void) => void;
  addCallback?: (element: T,callbackSuccess: () => void, callbackFinally: () => void) => void;
  addListURLParams?: SchoolURLParams;
  getSubtitleForElement?: (element: T) => string;
  getIconForElement?: (element: T) => string;
  addIcon?: string;
}

@Component({
  components: {TimePickerDialog}
})
export default class InputComp extends Vue {
  @Prop({required: true}) fieldValues!: InputField<any>;

  @VModel() entity!: string | number | boolean;

  useRule(input: string): string | boolean {
    if (this.fieldValues.rule === undefined) {
      return this.$t(this.fieldValues.ruleError || '').toString();
    }
    return (new RegExp(this.fieldValues.rule || '')).test(input)
        ? true : this.$t(this.fieldValues.ruleError || '').toString();
  }
}
