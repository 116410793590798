export default abstract class GenericEntity {
    _id!: string;
    active!: boolean;
}

export class GenericWrapper<T> {
    count!: number;

    get items(): T[] {
        return [];
    };
}