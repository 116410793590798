
//@ts-nocheck
import {Component, Watch} from 'vue-property-decorator'
import User from "@/assets/Entities/User";
import GenericEntityEditor from "@/components/generic/GenericEntityEditor.vue";
import {InputField} from "@/components/general/InputComp.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import {Action, SchoolURLParams} from "@/assets/service/Endpoints";
import Webservice from "@/assets/service/Webservice";
import SchoolClass from "@/assets/Entities/SchoolClass";
import {GenericWrapper} from "@/assets/Entities/GenericEntity";
import InputListComponent from "@/components/generic/InputListComponent.vue";

@Component({
  components: {InputListComponent}
})
export default class UserEditor extends GenericEntityEditor<User> {
  openDialog = false;
  showSafetyDialog = false;
  title: string = 'User.Dialog.Title.';
  classes: SchoolClass[] = [];

  image = undefined;
  uploadLabel = 'User.Dialog.File.Search';
  uploadActivator1 = 'User.Dialog.File.Button.Activate1';
  uploadActivator2 = 'User.Dialog.File.Button.Activate2';

  get uploadTitle() {
    return 'User.Dialog.File.Button.Activate' + (this.dialogModeA ? '1' : '2');
  }

  uploadButton = 'User.Dialog.File.Button.Upload';

  uploadImage() {
    let formData = new FormData();
    formData.append('image', this.image);
    formData.append('content-type', (this.image as File).type);
    formData.append('date', this.uploadUntil)
    formData.append('type', this.dialogModeA ? 'vaccination' : 'recovered');

    let params = this.params;

    params.type = TargetType.CERT;

    Webservice.performRequest(RequestType.POST, this.params,
        formData, val => {
          this.uploadSuccess = true;
          if (this.reloadCallback) {
            this.reloadCallback();
          }
        }, error => {/* TODO: handle me */
        }, () => {
          this.loading = false;
          this.uploadUntil = '';
          this.image = undefined;
        });
  }

  @Watch('openDialog')
  loadClasses() {
    if (this.openDialog && this.$store.getters.currentUser.role !== UserType.Student) {
      Webservice.performRequest<GenericWrapper<SchoolClass>>(RequestType.GET, {
        type: TargetType.DEPARTMENT,
        client: this.$route.params.client
      }, {}, val => this.$store.commit('SET_AVAILABLE_CLASSES', val.items));
    }
  }

  get withUpload(): boolean {
    return this.model?.id === this.$store.getters.currentUser.id || this.$store.getters.currentUser.role === UserType.Site_admin || this.$store.getters.currentUser.role === UserType.Teacher
  }

  get params(): SchoolURLParams {
    return {
      type: TargetType.USER,
      client: this.$route.params.client,
      userId: this.model?.id || this.editableEntity.id || this.model?._id || this.editableEntity._id
    }
  }

  get fields(): InputField[] {
    let fields: InputField[] = [
      {
        title: 'User.Dialog.Name',
        type: 'text',
        value: 'name'
      },
      {
        title: 'User.Dialog.Login',
        type: 'text',
        value: 'login'
      },
      {
        title: 'User.Dialog.Email',
        type: 'text',
        value: 'mail',
        rule: '^[^@\\s]+@[^@\\s.]+\\.[^@.\\s][^@.\\s]+',
        ruleError: 'User.Dialog.WrongMail'
      },
      {
        title: 'User.Dialog.Password',
        type: 'password',
        value: 'password'
      },
      {
        title: 'User.Dialog.MailNotification',
        type: 'checkbox',
        value: 'notification_mail'
      },
      {
        title: 'Settings.Time',
        type: 'time',
        value: 'notification_time'
      }
    ];
    if (this.$store.getters.currentUser.role === UserType.Admin || this.$store.getters.currentUser.role === UserType.Site_admin || this.$store.getters.currentUser.role === UserType.Teacher) {
      fields.push(
          {
            title: 'User.Dialog.Active',
            type: 'checkbox',
            value: 'active'
          });
      if (this.$store.getters.currentUser.role === UserType.Admin) {
        fields.push(
            {
              title: 'Class.Dialog.School',
              value: 'schoolId',
              type: 'select',
              items: this.$store.getters.availableSchools,
              itemText: 'name',
              itemValue: 'id'
            });
      }
      fields.push(
          {
            title: 'User.Dialog.Role.Title',
            value: 'role',
            type: 'select',
            items: this.userRoles,
          });
    }
    if (!this.create) {
      fields.push({
        title: 'User.Dialog.Class',
        value: this.editableEntity.id,
        type: 'custom',
        comp: 'input-list-component',
        items: this.classes,
        addListURLParams: {
          type: TargetType.DEPARTMENT,
          client: this.$route.params.client
        },
        addCallback: (schoolClass, callbackSuccess, callbackFinally) => {
          Webservice.performRequest(RequestType.POST, {
                type: TargetType.USER,
                client: this.$route.params.client,
                departmentId: schoolClass._id,
                action: Action.ADD
              }, {userId: this.editableEntity._id}, callbackSuccess,
              error => {
              },
              callbackFinally)
        },
        deleteCallback: (schoolClass, callbackSuccess, callbackFinally) => {
          Webservice.performRequest(RequestType.POST, {
                type: TargetType.USER,
                departmentId: schoolClass._id,
                client: this.$route.params.client,
                action: Action.DELETE
              },
              {userId: this.editableEntity._id}, callbackSuccess, error => {
              }, callbackFinally);
        },
        getSubtitleForElement: (element: SchoolClass) => {
          if (this.editableEntity.role === UserType.Teacher
              && element.headTeachers?.filter(value => value._id === this.editableEntity._id).length > 0) {
            return this.$t('Class.Dialog.HeadTeacher').toString();
          }
          return this.$t('User.Dialog.Role.' + this.editableEntity.role).toString();
        },
        getIconForElement: (element: SchoolClass) => {
          return 'mdi-account-group-outline';
        },
        addIcon: 'mdi-account-multiple-plus-outline'
      })
    }
    return fields;
  }

  get userRoles(): { text: string, value: string }[] {
    let roles: { text: string, value: string }[] = [];
    switch (this.$store.getters.currentUser.role as UserType) {
      case UserType.Admin:
        roles.push({value: 'admin', text: this.$t('User.Dialog.Role.admin')});
      case UserType.Site_admin:
        roles.push({value: 'site_admin', text: this.$t('User.Dialog.Role.site_admin')});
      case UserType.Teacher:
        roles.push({value: 'teacher', text: this.$t('User.Dialog.Role.teacher')});
        roles.push({value: 'user', text: this.$t('User.Dialog.Role.user')});
        break;
      case UserType.Student:
        break;
      default:
        throw new Error('Wrong Value');
    }
    return roles;
  }

  createCleanEntity(): User {
    return new User();
  }

  entityPreparation() {
    if (this.editableEntity.notification_time === undefined) {
      this.editableEntity.notification_mail = '';
    }
    if (this.editableEntity.certificates) {
      this.imgArr = [];
      for (let i = 0; i < this.editableEntity.certificates.length; i++) {
        Webservice.performRequest(RequestType.GET,
            {
              type: TargetType.CERT,
              userId: this.editableEntity._id,
              client: this.$route.params.client,
              certId: this.editableEntity.certificates[i].img
            }, {}, (val, header) => {
              const image = Object.assign({}, this.editableEntity.certificates[i]);
              image.ext = val['content-type']?.replace('application/', '');
              image.url = val.signedUrl;
              this.imgArr.push(image);
            });
      }
    }
    this.editableEntity.schoolId = this.model.schoolId;
    this.classes = this.editableEntity.departments;
  }

  isDirty(): boolean {
    return (this.editableEntity.name !== (this.model?.name || '')
        || this.editableEntity.mail !== (this.model?.mail || ''));
  }
}
