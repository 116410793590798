/**
 * Set a Cookie
 * @param name Name of the cookie
 * @param val Value of the cookie
 * @param duration Duration of the cookie in seconds
 */
export function setCookie(name: string, val: string, duration: number) {
    const date = new Date();

    date.setTime(date.getTime() + (duration * 1000));

    document.cookie = `${name}=${val}; expires=${date.toUTCString()}; path=/`;
}

export function getCookie(name: string): string {
    const val = `; ${document.cookie}`;
    const parts: string[] = val.split(`; ${name}`);

    if (parts.length === 2) {
        return ((parts.pop() as string).split(';').shift() as string).slice(1);
    }
    return '';
}

export function deleteCookie(name: string) {
    document.cookie = `${name}=; expires=expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}
