import {TargetType} from "@/assets/constants/Enums";

export default class Endpoints {
    public static LOGIN_ENDPOINT: string = 'login';

    /****************************************************************
     ******************* School related Endpoints *******************
     ****************************************************************/
    private static CLIENT_GENERAL_ENDPOINT: string = '/client';

    /***************************************************************
     ***************** Department related Endpoints ****************
     ***************************************************************/
    private static DEPARTMENT_GENERAL_ENDPOINT: string = '/department';
    private static DEPARTMENT_ADD_USER_ENDPOINT: string = '/addUser';
    private static DEPARTMENT_DELETE_USER_ENDPOINT: string = '/deleteUser';
    private static DEPARTMENT_USERS_DAILY_ENDPOINT: string = '/users-daily';
    private static DEPARTMENT_DELETE_ENDPOINT: string = '/delete';

    /****************************************************************
     ******************** User related Endpoints ********************
     ****************************************************************/

    private static USER_GENERAL_ENDPOINT: string = '/user';
    private static UPLOAD_TEST_ENDPOINT: string = '/uploadTest';
    private static UPLOAD_CERT_ENDPOINT: string = '/uploadCertificate';
    private static USER_ABSENT_ENDPOINT: string = '/uploadAbsent';
    private static GET_CERT_ENDPOINT: string = '/getCertificate';
    private static GET_TEST_ENDPOINT: string = '/getTest';

    /****************************************************************
     ******************* Guest related Endpoints ********************
     ****************************************************************/

    private static GUEST_GENERAL_ENDPOINT: string = '/guest';
    private static GUEST_SEND_QR_ENDPOINT: string = '/sendQr';
    private static GUEST_SEND_IMAGE_ENDPOINT: string = '/sendImage';
    private static GUEST_LIST_GUESTS_ENDPOINT: string = '/listGuests';
    private static GUEST_LIST_DATES_ENDPOINT: string = '/listDates';
    private static GUEST_GET_UPLOAD_ENDPOINT: string = '/getUpload';

    public static getSchoolURL(params: SchoolURLParams): string {
        let url = '';

        if (params.client) {
            url += '/' + params.client
            if (params.type === TargetType.DEPARTMENT || (params.departmentId && params.departmentId !== '')) {
                url += this.DEPARTMENT_GENERAL_ENDPOINT;
                if (params.departmentId) {
                    url += '/' + params.departmentId;
                    if (params.action) {
                        if (params.type === TargetType.USER) {
                            switch (params.action) {
                                case Action.ADD:
                                    url += this.DEPARTMENT_ADD_USER_ENDPOINT;
                                    break;
                                case Action.DELETE:
                                    url += this.DEPARTMENT_DELETE_USER_ENDPOINT;
                                    break;
                            }
                        } else if (params.action === Action.DELETE) {
                            url += this.DEPARTMENT_DELETE_ENDPOINT;
                        }else if (params.action === Action.DAILY) {
                            url += this.DEPARTMENT_USERS_DAILY_ENDPOINT;
                        }
                    }
                }
            } else if (params.type === TargetType.USER || (params.userId && params.userId !== '')) {
                url += this.USER_GENERAL_ENDPOINT;
                if (params.userId) {
                    url += '/' + params.userId;
                }
                if (params.certId && params.certId !== '') {
                    switch (params.type) {
                        case TargetType.CERT:
                            url += this.GET_CERT_ENDPOINT;
                            break;
                        case TargetType.TEST:
                            url += this.GET_TEST_ENDPOINT
                            break;
                    }
                    url += '/' + params.certId;
                } else if (params.type !== TargetType.USER) {
                    switch (params.type) {
                        case TargetType.CERT:
                            url += this.UPLOAD_CERT_ENDPOINT;
                            break;
                        case TargetType.TEST:
                            url += this.UPLOAD_TEST_ENDPOINT;
                            break;
                        case TargetType.ABSENT:
                            url += this.USER_ABSENT_ENDPOINT;
                            break;
                    }
                }
            } else if (params.type === TargetType.GUEST_QR) {
                url += this.GUEST_GENERAL_ENDPOINT + this.GUEST_SEND_QR_ENDPOINT;
            } else if (params.type === TargetType.GUEST_IMAGE) {
                url += this.GUEST_GENERAL_ENDPOINT + this.GUEST_SEND_IMAGE_ENDPOINT;
            } else if (params.type === TargetType.GUEST_DATES) {
                url += this.GUEST_GENERAL_ENDPOINT + this.GUEST_LIST_DATES_ENDPOINT;
            } else if (params.type === TargetType.GUEST_LIST) {
                url += this.GUEST_GENERAL_ENDPOINT + this.GUEST_LIST_GUESTS_ENDPOINT;
            } else if (params.type === TargetType.GUEST_UPLOADS && params.guestId) {
                url += this.GUEST_GENERAL_ENDPOINT + this.GUEST_GET_UPLOAD_ENDPOINT + '/' + params.guestId;
            }
        } else {
            url = this.CLIENT_GENERAL_ENDPOINT;
            if (params.clientId && params.clientId !== '') {
                url += '/' + params.clientId
            }
        }
        return url;
    }
}

export class SchoolURLParams {
    type!: TargetType;
    client?: string;
    clientId?: string;
    departmentId?: string;
    userId?: string;
    certId?: string;
    action?: Action;
    guestId?: string;
}

export const enum Action {
    DELETE = 'DELETE',
    ADD = 'ADD',
    DAILY = 'DAILY',
}
