export enum UserType {
    Site_admin = 'site_admin',
    Admin = 'admin',
    Teacher = 'teacher',
    Student = 'user'
}

export const enum RequestType {
    GET = 'GET',
    POST = 'ADD',
}

export const enum TargetType {
    CLIENT,
    DEPARTMENT,
    USER,
    TEST,
    ABSENT,
    GUEST_QR,
    GUEST_IMAGE,
    GUEST_LIST,
    GUEST_DATES,
    GUEST_UPLOADS,
    CERT,

}
