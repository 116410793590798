























import {Component, Vue} from 'vue-property-decorator';
import Webservice from '@/assets/service/Webservice';

@Component
export default class LogoutDialog extends Vue {
  openDialog: boolean = false;

  logout(): void {
    Webservice.logout();
  }
}
