





































































































































































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator'
import InputComp, {InputField} from "@/components/general/InputComp.vue";
import Webservice from "@/assets/service/Webservice";
import {RequestType} from "@/assets/constants/Enums";
import {Action, SchoolURLParams} from "@/assets/service/Endpoints";
import GenericEntity from "@/assets/Entities/GenericEntity";
import {makeId} from "@/assets/util/IdUtil";
import DatePicker from "@/components/contacts/DatePicker.vue";
import pdf from "vue-pdf";

@Component({components: {DatePicker, InputComp, pdf}})
export default class GenericEntityEditor<T extends GenericEntity> extends Vue {
  openDialog: boolean = false;
  showSafetyDialog: boolean = false;
  showSafetyDeleteDialog: boolean = false;
  canBeDeleted: boolean = true;

  impfExpire: number = 9;
  genesExpire: number = 3;

  rndKey: string = makeId(5);

  @Model() model!: T;

  @Prop() reloadCallback!: () => void;

  editableEntity!: T;

  get withUpload(): boolean {
    return false;
  }

  uploadLabel: string = '';

  image: any = null;
  uploadDialogOpen: boolean = false;
  uploadActivator1: string = '';
  uploadActivator2: string = '';
  dialogModeA: Boolean = true;

  uploadSuccess: boolean = false;

  get uploadTitle(): string {
    return '';
  }

  imgArr: { type: string, date: string, img: string, url: string }[] = [];

  imgAext: string = '';

  uploadButton: string = '';

  uploadImage!: () => void;
  uploadUntil: String = '';

  title!: string;
  fields!: InputField<GenericEntity>[];

  params!: SchoolURLParams;

  createCleanEntity(): T {
    throw new Error('Never ever call this! Overwrite it!')
  };

  loading: boolean = false;

  @Watch('openDialog')
  loadEntity() {
    if (this.openDialog && !this.create) {
      this.loading = true;
      Webservice.performRequest<T>(RequestType.GET, this.params, {}, (val) => {
        this.editableEntity = (val as T);
        this.entityPreparation();
        this.rndKey = makeId(5);
      }, error => {
      }, () => {
        this.loading = false
      })
    }
  }

  get create(): boolean {
    return this.model === undefined;
  }

  entityPreparation() {
  }

  save(): void {
    this.loading = true;
    Webservice.performRequest(RequestType.POST, this.params,
        this.editableEntity, val => {
          this.reloadCallback();
          this.clearAndHide();
        }, error => {/* TODO: handle me */
        }, () => {
          this.loading = false;
        })
  }

  deleteEntity() {
    if (this.showSafetyDeleteDialog) {
      this.loading = true;
      this.editableEntity.active = false;
      this.params.action = Action.DELETE;
      Webservice.performRequest(RequestType.POST, this.params, this.editableEntity, val => {
            this.reloadCallback();
            this.clearAndHide();
          }, error => {/*TODO handle */
          },
          () => {
            this.loading = false
          })
    } else {
      this.showSafetyDeleteDialog = true;
    }
  }

  safeToCancel() {
    this.showSafetyDialog = false;
    this.clearAndHide();
  }

  isDirty(): boolean {
    return true
  };

  closeDialog(): void {
    if (this.isDirty()) {
      this.showSafetyDialog = true;
    } else {
      this.clearAndHide();
    }
  }

  protected clearAndHide(): void {
    this.editableEntity = this.create ? this.createCleanEntity() : this.model;
    this.openDialog = false;
    this.showSafetyDeleteDialog = false;
  }

  created() {
    this.openDialog = false;
    this.editableEntity = this.create ? this.createCleanEntity() : this.model;
  }
}
