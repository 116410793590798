












































import {Component, Prop, Vue} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";
import {RequestType, TargetType} from "@/assets/constants/Enums";
import {QuestionStatus} from "@/assets/Entities/User";

@Component({
  components: {}
})
export default class Symptoms extends Vue {
  @Prop() onSend!: () => void;
  @Prop() openSuccessDialog!: (status: QuestionStatus) => void;

  loading: boolean = false;
  error: boolean = false;

  // symptoms: (string | string[])[] = [
  //   'none',
  //   [
  //     'test_negative',
  //     'test_positive'
  //   ],
  //   [
  //     'cough_light',
  //     'cough_medium',
  //     'cough_strong'
  //   ],
  //   'sniff',
  //   [
  //     'temperature_light',
  //     'temperature_middle',
  //     'temperature_strong'
  //   ],
  //   'sore_throat',
  //   'lost_smell',
  //   'pain'
  // ]

  checkedSymptoms: string[] = [];
  image: any = null;

  toggleSymptom(symptom: string) {
    if (this.checkedSymptoms.includes(symptom)) {
      this.checkedSymptoms = this.checkedSymptoms.filter(value => value !== symptom);
    } else {
      if (symptom.includes('_')) {
        const prefix: string = symptom.split('_')[0];

        this.checkedSymptoms = this.checkedSymptoms.filter(value => !value.includes(prefix))
      }
      if (symptom === 'none') {
        this.checkedSymptoms = this.checkedSymptoms.filter(value => value.includes('test'));
      } else if (this.checkedSymptoms.includes('none') && !symptom.includes('test')) {
        this.checkedSymptoms = this.checkedSymptoms.filter(value => value !== 'none');
      }
      this.checkedSymptoms.push(symptom);
    }
  }

  cancel() {
    this.checkedSymptoms = [];
    this.onSend();
  }

  send() {
    // if (this.checkedSymptoms.length === 0) {
    //   this.error = true;
    // } else {
    this.loading = true;
    this.error = false;

    let formData = new FormData();

    // formData.append('symptoms', JSON.stringify(this.checkedSymptoms));

    formData.append('date', (new Date()).toLocaleDateString('de'));

    formData.append('type', 'test');

    if (this.image) {
      formData.append('image', this.image);
      formData.append('content-type', (this.image as File).type);
    }

    Webservice.performRequest<SymptomResponse>(RequestType.POST, {
          type: TargetType.TEST,
          client: this.$route.params.client,
          userId: this.$store.getters.currentUser.id
        },
        formData,
        val => {
          if ((val as SymptomResponse).status !== undefined) {
            this.openSuccessDialog((val as SymptomResponse).status);
          }
        }, error => {
        }, () => {
          this.loading = false;
          this.checkedSymptoms = [];
          this.onSend();
        });
  }
}

class SymptomResponse {
  status!: QuestionStatus
}
