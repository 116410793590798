import Repository from "@/assets/service/Repository";
import router from "@/router";
import {setId, unsetId} from "@/assets/util/AuthUtil";
import Endpoints, {SchoolURLParams} from "@/assets/service/Endpoints";
import {AxiosRequestHeaders, AxiosResponse, AxiosResponseHeaders} from "axios";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import {Error} from "@/assets/Entities/Error";
import store from "@/store";
import User from "@/assets/Entities/User";

export default class Webservice {
    public static login(user: string, password: string, callbackFail: (reason) => void, callbackFinally: () => void, client?: string) {
        Repository.post<{ jwt: string, user: User, client: string, error?: string, msg?: string }>((client ? ('/' + client + '/') : '') + Endpoints.LOGIN_ENDPOINT,
            {login: user, password: password})
            .then(value => {
                if (value.data.error) {
                    callbackFail(value.data.error);
                    return;
                } else if (value.data.msg) {
                    callbackFail(value.data.msg);
                    return;
                }
                setId(value.data.jwt);
                // const user1 = new User(value.data.name, value.data.role,);
                const user1 = value.data.user;

                if (!(client)) {
                    user1.role = UserType.Admin;
                } else {
                    user1.role = UserType.Site_admin;
                }
                Repository.defaults.headers.common['Authorization'] = `${value.data.jwt}`;
                store.commit('SET_CURRENT_USER', user1);
                if (router.currentRoute.query.redirect) {
                    router.replace(router.currentRoute.query.redirect as string);
                } else {
                    router.replace((client ? ('/' + client) : '')+'/home');
                }
            }).catch(callbackFail)
            .finally(callbackFinally);
    }

    public static logout() {
        // Repository.post(this.LOGOUT_ENDPOINT)
        //     .then(value => {
        Repository.defaults.headers.common['Authorization'] = '';
        unsetId();
        location.reload();
        //     })
    }

    public static performRequest<T>(type: RequestType,
                                    params: SchoolURLParams,
                                    item: T | any,
                                    callbackSuccess?: (val?: T | string, headers?: AxiosResponseHeaders) => void,
                                    callbackFail?: (error: any) => void,
                                    callbackFinally?: () => void,
                                    header?: AxiosRequestHeaders,
                                    progressSetter?: (progress) => void): void {

        let url: string = Endpoints.getSchoolURL(params);

        let request: Promise<AxiosResponse<T | Error | UploadUrl>>;

        let imgTmp;
        let typeTmp;
        switch (type) {
            case RequestType.GET:
                request = Repository.get<T | Error>(url, {params: item});
                break;
            case RequestType.POST:
                let formData;
                if (params.type === TargetType.CERT) {
                    formData = (item as FormData);
                    imgTmp = formData.get('image');
                    item = {
                        type: formData.get('type'),
                        date: formData.get('date'),
                        'content-type': formData.get('content-type')
                    };
                } else if (params.type === TargetType.TEST) {
                    formData = (item as FormData);
                    imgTmp = formData.get('image');
                    item = {
                        type: formData.get('type'),
                        date: formData.get('date'),
                        'content-type': formData.get('content-type')
                    };
                } else if (params.type === TargetType.GUEST_IMAGE) {
                    formData = (item as FormData);
                    imgTmp = formData.get('image');
                    item = {
                        type: formData.get('type'),
                        name: formData.get('name'),
                        company: formData.get('company'),
                        'content-type': formData.get('content-type')
                    };
                }

                request = Repository.request<T | Error | UploadUrl>({
                    url: url,
                    method: 'POST',
                    data: item,
                    headers: header,
                    onUploadProgress: progressSetter
                });
                break;
            default:
                throw new TypeError('Unsupported type: ' + type);
        }

        request.then(value => {
            if (value.data instanceof Error && value.data.error && callbackFail) {
                callbackFail(value.data.error);
            } else if (value.data && callbackSuccess) {
                if (type === RequestType.POST
                    && (params.type === TargetType.CERT
                        || params.type === TargetType.TEST
                        || params.type === TargetType.GUEST_IMAGE)) {
                    let dataToSend = new FormData();
                    for (const [k, v] of Object.entries<string>((value.data as UploadUrl).fields)) {
                        dataToSend.append(k, v);
                    }
                    dataToSend.append('file', imgTmp);
                    // dataToSend.append('Content-Type', typeTmp);
                    Repository.request({
                        url: (value.data as UploadUrl).signedUrl,
                        data: dataToSend,
                        transformRequest: (data, headers) => {
                            delete headers?.common['Authorization']
                            return data;
                        },
                        method: 'POST'
                    })
                        .then(value1 => {
                            callbackSuccess(value1.data as T, value1.headers)
                        }).finally(callbackFinally);
                } else {
                    callbackSuccess(value.data as T, value.headers);
                }
            }
        }).catch(callbackFail)
            .finally(() => {
                if (!(params.type === TargetType.CERT || params.type === TargetType.TEST || params.type === TargetType.GUEST_IMAGE)
                    && callbackFinally) {
                    callbackFinally()
                }
            });
    }
}

class UploadUrl {
    signedUrl!: string;
    id!: string;
    fields!: any;
}
